.orders-page {
  .orders-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    margin-top: 3vh;
  }

  .orders-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
  }

  .important-deadline {
    font-size: 18px;
    font-weight: bold;
    padding-left: 8px;
    color: red;
  }

  .day-header {
    color: #474747;
    font-weight: bold;
    font-size: 22px;
    padding-left: 12px;
    padding-top: 5px;
    border-bottom: 2px solid #474747;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .header {
    color: "blue";
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 5px;
  }

  .orders-header {
    padding: 10px;
    background-color: "white";
  }

  .font-size-18 {
    font-size: 18px;
  }

  .font-size-16 {
    font-size: 16px;
  }

  .orders-empty {
    text-align: center;
    padding: 40px;
    font-size: 24px;
  }

  .fraction {
    padding-right: 5px;
    display: inline-flex;
    align-items: center;
  }

  .line-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: inline;
    }

    .right {
      display: inline;
    }
  }

  .transform-status {
    transform: translateY(-5px);
  }

  .transform-type {
    transform: translateY(3px);
  }

  .flex-load-contact {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;

    .load {
      flex: 1;
    }

    @media (max-width: 800px) {
      .contact-person {
        flex: 100%;
        padding-top: 10px;
        text-align: start;
      }

      .load {
        flex: 100%;
      }
    }
  }
}
