.checkout-page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.checkout-items-container {
    flex: 1;
    width: 100%;
    gap: 1;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    background: linear-gradient(90deg,
            rgba(250, 250, 250, 1) 0%,
            rgba(246, 246, 246, 1) 33%,
            rgba(250, 250, 250, 1) 100%);
}